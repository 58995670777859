import React from 'react'
import { Helmet } from 'react-helmet'
import { v4 as uuidv4 } from 'uuid'
import HeaderSEO from '../components/partials/HeaderSEO'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'

import { StaticImage } from 'gatsby-plugin-image'

const welcome = () => {
  const data = useStaticQuery(graphql`
    query CustomerAreaSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "CustomerArea" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
      allContentfulLensesBlog(sort: { order: DESC, fields: date }, limit: 3) {
        edges {
          node {
            date
            description
            title
            slug
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  const news = data.allContentfulLensesBlog.edges
  return (
    <Layout onlyLogo={true}>
      <HeaderSEO pageSEO={pageSEO} />
      <StaticImage
        className="home-fixed-top"
        src="../resources/images/homepage/hero_lens.svg"
        placeholder="blurred"
        alt="Hero Lenses"
      />
      <section className="pt-5 pl-5 mt-4 mobile-padding-reset">
        <div className="container-flui1d ">
          <div className="row mt-5 pl-4">
            <div className="col-md-6 pt-0 col-12 mobile-margin-top">
              <p className="title breadcrumb-text pt-4">
                <span className="title_text">CUSTOMER AREA</span>
              </p>
              <h1 className="hero-description-dark pb-0 f-40" id="d-paragraph">
                Welcome to Lenses!
              </h1>
              <p className="title breadcrumb-text pr-5">
                <span className="title_text">FIND VALUABLE RESOURCES</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="p-5">
        <div className="container-fluid p-3 mobile-padding-reset">
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="row">
                {/* Docs */}
                <div className="col-sm-6 col-lg-4 col-md-4">
                  <a
                    href="https://docs.lenses.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="iq-fancy-box text-center scale-on-hover">
                      <div className="iq-icon">
                        <i aria-hidden="true" className="fa fa-file-text-o"></i>
                      </div>
                      <div className="fancy-content">
                        <h2 className="h3-like f-20 fw-600 pt-4 pb-2">
                          <a
                            href="https://docs.lenses.io"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Documentation
                          </a>
                        </h2>
                        <p className="welcome-p-min-h">
                          Installation instructions, development tools and
                          integrations
                        </p>
                      </div>
                      <a
                        href="https://docs.lenses.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="btn btn-outline-secondary f-13">
                          Read Docs
                        </button>
                      </a>
                    </div>
                  </a>
                </div>
                {/* HelpCenter */}
                <div className="col-sm-6 col-lg-4 col-md-4">
                  <a
                    href="https://help.lenses.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="iq-fancy-box text-center scale-on-hover">
                      <div className="iq-icon">
                        <i aria-hidden="true" className="fa fa-users"></i>
                      </div>
                      <div className="fancy-content">
                        <h2 className="h3-like f-20 fw-600 pt-4 pb-2">
                          <a
                            href="https://help.lenses.io"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Help Center
                          </a>
                        </h2>
                        <p className="welcome-p-min-h">
                          Best practises and how to apply real-time DataOps
                        </p>
                      </div>
                      <a
                        href="https://help.lenses.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="btn btn-outline-secondary f-13">
                          Learn
                        </button>
                      </a>
                    </div>
                  </a>
                </div>
                {/* Secure area */}
                <div className="col-sm-6 col-lg-4 col-md-4">
                  <a
                    href="https://secure.lenses.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="iq-fancy-box text-center scale-on-hover">
                      <div className="iq-icon">
                        <i aria-hidden="true" className="fa fa-lock"></i>
                      </div>
                      <div className="fancy-content">
                        <h2 className="h3-like f-20 fw-600 pt-4 pb-2">
                          <a
                            href="https://secure.lenses.io"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Secure Area
                          </a>
                        </h2>
                        <p className="welcome-p-min-h">
                          Access private files like license keys. Request access
                          at{' '}
                          <a
                            className="link-text"
                            href="mailto:support@lenses.io"
                          >
                            support@lenses.io
                          </a>{' '}
                        </p>
                      </div>
                      <a
                        href="https://secure.lenses.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="btn btn-outline-secondary f-13">
                          Access
                        </button>
                      </a>
                    </div>
                  </a>
                </div>
              </div>
              <div className="row">
                {/* Slack */}
                <div className="col-sm-6 col-lg-4 col-md-4 mt-4">
                  <a
                    href="https://launchpass.com/lensesio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="iq-fancy-box text-center scale-on-hover">
                      <div className="iq-icon">
                        <i aria-hidden="true" className="fa fa-slack"></i>
                      </div>
                      <div className="fancy-content">
                        <h2 className="h3-like f-20 fw-600 pt-4 pb-2">
                          <a
                            href="https://launchpass.com/lensesio"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Real-time chat
                          </a>
                        </h2>
                        <p className="welcome-p-min-h">
                          The DataOps community for real-time discussions (on
                          Slack)
                        </p>
                      </div>
                      <a
                        href="https://launchpass.com/lensesio"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="btn btn-outline-secondary f-13">
                          Chat
                        </button>
                      </a>
                    </div>
                  </a>
                </div>
                {/* Support */}
                <div className="col-sm-6 col-lg-4 col-md-4 mt-4 mobile-margin-top">
                  <a
                    href="https://support.lenses.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="iq-fancy-box text-center scale-on-hover">
                      <div className="iq-icon">
                        <i aria-hidden="true" className="fa fa-users"></i>
                      </div>
                      <div className="fancy-content">
                        <h2 className="h3-like f-20 fw-600 pt-4 pb-2">
                          <a
                            href="https://support.lenses.io"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Support Portal
                          </a>
                        </h2>
                        <p className="welcome-p-min-h">
                          Access your support portal and manage your tickets.
                          Request your access at{' '}
                          <a
                            className="link-text"
                            href="mailto:support@lenses.io"
                          >
                            support@lenses.io
                          </a>
                        </p>
                      </div>
                      <a
                        href="https://support.lenses.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="btn btn-outline-secondary f-13">
                          Enter
                        </button>
                      </a>
                    </div>
                  </a>
                </div>
                {/* Downloads */}
                <div className="col-sm-6 col-lg-4 col-md-4 mt-4">
                  <a href="/start/" target="_blank" rel="noopener noreferrer">
                    <div className="iq-fancy-box text-center scale-on-hover">
                      <div className="iq-icon">
                        <i
                          aria-hidden="true"
                          className="fa fa-cloud-download"
                        ></i>
                      </div>
                      <div className="fancy-content">
                        <h2 className="h3-like f-20 fw-600 pt-4 pb-2">
                          Downloads
                        </h2>
                        <p className="welcome-p-min-h">
                          Downloads, add-ons, open source libraries, clients and
                          more
                        </p>
                      </div>
                      <a
                        href="/start/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="btn btn-outline-secondary f-13">
                          Downloads
                        </button>
                      </a>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6 col-lg-4 col-md-4 mt-4">
                  <a
                    href="https://zc.vg/sf/7z95"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="iq-fancy-box text-center scale-on-hover">
                      <div className="iq-icon">
                        <i aria-hidden="true" className="fa fa-bell"></i>
                      </div>
                      <div className="fancy-content">
                        <h2 className="h3-like f-20 fw-600 pt-4 pb-2">
                          <a
                            href="https://zc.vg/sf/7z95"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Lenses Notifications
                          </a>
                        </h2>
                        <p className="welcome-p-min-h">
                          Subscribe to notifications for new product releases
                          and updates
                        </p>
                        <a
                          href="https://zc.vg/sf/7z95"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="btn btn-outline-secondary f-13">
                            Subscribe
                          </button>
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6 col-lg-4 col-md-4 mt-4">
                  <a
                    href="/company/newsletter/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="iq-fancy-box text-center scale-on-hover">
                      <div className="iq-icon">
                        <i aria-hidden="true" className="fa fa-rss"></i>
                      </div>
                      <div className="fancy-content">
                        <h2 className="h3-like f-20 fw-600 pt-4 pb-2">
                          <a
                            href="/company/newsletter/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Newsletter
                          </a>
                        </h2>
                        <p className="welcome-p-min-h">
                          Subscribe to our newsletter to stay tuned!
                        </p>
                      </div>
                      <a
                        href="/company/newsletter/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="btn btn-outline-secondary f-13">
                          Subscribe
                        </button>
                      </a>
                    </div>
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-4 col-md-4 mt-4">
                  <div className="iq-fancy-box text-center">
                    <div className="iq-icon">
                      <i aria-hidden="true" className="fa fa-comment"></i>
                    </div>
                    <div className="fancy-content">
                      <h2 className="h3-like f-20 fw-600 pt-4 pb-2">
                        <a
                          href="/videos/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Videos
                        </a>
                      </h2>
                    </div>
                    <a
                      href="/videos/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-outline-secondary f-13">
                        View Videos
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-md-4 mt-4">
                  <div className="iq-fancy-box text-center">
                    <div className="iq-icon">
                      <i aria-hidden="true" className="fa fa-comment"></i>
                    </div>
                    <div className="fancy-content">
                      <h2 className="h3-like f-20 fw-600 pt-4 pb-2">
                        <a
                          href="/webinars/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Webinars
                        </a>
                      </h2>
                    </div>
                    <a
                      href="/webinars/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-outline-secondary f-13">
                        View Webinars
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-md-4 mt-4">
                  <div className="iq-fancy-box text-center">
                    <div className="iq-icon">
                      <i aria-hidden="true" className="fa fa-comment"></i>
                    </div>
                    <div className="fancy-content">
                      <h2 className="h3-like f-20 fw-600 pt-4 pb-2">
                        <a
                          href="/resources/#tutorials"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Tutorials
                        </a>
                      </h2>
                    </div>
                    <a
                      href="/resources/#tutorials"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-outline-secondary f-13">
                        View Tutorials
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-8 col-md-8 mt-4">
                  <div className="iq-fancy-box">
                    <div className="fancy-content pl-4">
                      <p
                        className="hero-description-dark pb-0 f-40"
                        id="d-paragraph"
                      >
                        Useful links
                      </p>
                      <ol>
                        <li>
                          <a
                            className="link-text"
                            href="https://docs.lenses.io/4.3/installation/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Install Lenses
                          </a>
                        </li>
                        <li>
                          <a
                            className="link-text"
                            href="https://docs.lenses.io/4.3/sql/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn Streaming SQL
                          </a>
                        </li>
                        <li>
                          <a
                            className="link-text"
                            href="https://docs.lenses.io/4.3/tools/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Development Tools
                          </a>
                        </li>
                        <li>
                          <a
                            className="link-text"
                            href="https://help.lenses.io/guides/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Usage best practises
                          </a>
                        </li>
                        <li>
                          <a
                            className="link-text"
                            href="/kafka-connectors/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Kafka Data Connectors
                          </a>
                        </li>
                        <li>
                          <a
                            className="link-text"
                            href="/blog/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Lenses Blog
                          </a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <ul className="info-share mt-4">
                  <div className="row pl-4">
                    <li className="mt-2">
                      <a
                        title="Twitter"
                        href="https://twitter.com/lensesio"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="mt-2">
                      <a
                        title="Youtube"
                        href="https://www.youtube.com/c/Lensesio"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          aria-hidden="true"
                          className="fa fa-youtube-play"
                        ></i>
                      </a>
                    </li>
                    <li className="mt-2">
                      <a
                        title="Medium"
                        href="https://medium.com/landoop"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-medium"></i>
                      </a>
                    </li>
                    <li className="mt-2">
                      <a
                        title="Github"
                        href="https://github.com/lensesio"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-github"></i>
                      </a>
                    </li>
                    <li className="mt-2">
                      <a
                        title="Linkedin"
                        href="https://linkedin.com/company/lensesio"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i aria-hidden="true" className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
            <div className="col-md-3 pl-3 pr-3 mr-3 ml-3 ipad-padding-reset mobile-margin-top">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-fancy-box-news">
                    <div className="news welcome-side-container border rounded shadow-sm bg-white">
                      {news.map((node) => {
                        // Format used to render the date
                        const dateOptions = {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        } as const
                        // Grabbing the date from Contentful Node
                        let tempDate = node.node.date
                        // Create new Date object from the tempDate
                        let dateToDisplay = new Date(tempDate)
                        // Getting the year from the date - used in the url
                        let year = dateToDisplay.getFullYear()
                        // Getting the month from the date & properly formatting - used in the url
                        let month = dateToDisplay.getMonth()
                        month++
                        let monthString = ('0' + month).slice(-2)
                        return (
                          <div className="p-2 m-3" key={uuidv4()}>
                            <p className="f-14 mb-0">
                              {dateToDisplay.toLocaleDateString(
                                'en-US',
                                dateOptions
                              )}
                            </p>
                            <h2 className="f-20 p-0 pt-1 pb-2">
                              <a
                                className="text-dark "
                                href={`/blog/${year}/${monthString}/${node.node.slug}/`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {node.node.title}
                              </a>{' '}
                            </h2>
                            <p>
                              {' '}
                              {`${node.node.description.substring(0, 60)}...`}
                              <br />
                              <br />
                              <a
                                className="link-text"
                                href={`/blog/${year}/${monthString}/${node.node.slug}/`}
                              >
                                Read the blog
                              </a>
                            </p>
                            <div className="welcome-side-container-border-bottom"></div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4 px-0">
                    <div className="iq-fancy-box text-center">
                      <a
                        className="twitter-timeline"
                        data-height="1020"
                        href="https://twitter.com/lensesio?ref_src=twsrc%5Etfw"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Tweets by lensesio
                      </a>
                      <Helmet>
                        <script
                          async
                          src="https://platform.twitter.com/widgets.js"
                          charSet="utf-8"
                        ></script>
                      </Helmet>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default welcome
